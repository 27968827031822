@import "variables.scss";

.filter-panel{
  border-radius: 50%;
  margin: 10px 0px 5px 10px;
  cursor: pointer;
  background-color: $gray; 
  border: 3px solid $black;
  height: 46px;
  width: 46px;
  transition: all 0.3s ease;
  font-size: 25px;
  color: rgb(54, 54, 54);
  font-weight: bold;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);

  &:hover{
    background-color: rgb(212, 216, 221);
  }

}

.filter-panel-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-icon{
  margin: -2px 0px 5px -5px;
}
  