.map {
  width: 100vw;
  height: 100vh;
  animation: fadeIn 2s ease-in-out;
}

.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-bottom-left{
  display: none;
}

.ws-image{
  max-width: 600px;
  max-height: 400px;
  flex:2;
}

.ws-popup-text{
  background-color: white;
  max-width: 260px;
  height: 400px;
  display: flex;
  justify-content:space-between;
  flex-direction:column;
  flex: 1;
}

.ws-box-size{
  position: relative;
}

.image-header-text{
  margin: 20px
}

.image-text{
  margin: 0 20px 0 20px;
}

.last-image-text{
  margin: 0 20px 20px 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.enlarged-image {
  max-height: 80vh;
  max-width: 80vw;
}

.overlay .enlarged-image {
  display: block;
}

.marker-style {
  cursor: 'pointer';
  fill: '#d00';
  stroke: 'none';
  width: 16px;
  height: 16px;
};

.box-shadow-overlay{
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
}

.ws-popup{
  min-width: 870px;

  .mapboxgl-popup-tip{
    border: 20px solid transparent;
  }
  .mapboxgl-popup-content{
    padding: 0px;
    border-radius: 30px;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.397);
  }
}

.ws-popup-black{
  .mapboxgl-popup-tip{
    border-top-color: $black !important;
  }
  .mapboxgl-popup-content{
    background-color: $black !important;
  }
}

.ws-popup-white{
  .mapboxgl-popup-tip{
    border-top-color: $white !important;
  }
  .mapboxgl-popup-content{
    background-color: $white !important;
  }
}

.ws-popup-gray{
  .mapboxgl-popup-tip{
    border-top-color: $gray !important;
  }
  .mapboxgl-popup-content{
    background-color: $gray !important;
  }
}

.ws-popup-clutter{
  .mapboxgl-popup-tip{
    border-top-color: $clutter !important;
  }
  .mapboxgl-popup-content{
    background-color: $clutter !important;
  }
}

.ws-popup-garbage{
  .mapboxgl-popup-tip{
    border-top-color: $garbage !important;
  }
  .mapboxgl-popup-content{
    background-color: $garbage !important;
  }
}

.ws-popup-graffiti{
  .mapboxgl-popup-tip{
    border-top-color: $graffiti !important;
  }
  .mapboxgl-popup-content{
    background-color: $graffiti !important;
  }
}

.ws-popup-bad-billboard{
  .mapboxgl-popup-tip{
    border-top-color: $bad_billboard !important;
  }
  .mapboxgl-popup-content{
    background-color: $bad_billboard !important;
  }
}

.ws-popup-potholes{
  .mapboxgl-popup-tip{
    border-top-color: $potholes !important;
  }
  .mapboxgl-popup-content{
    background-color: $potholes !important;
  }
}

.ws-popup-construction-road{
  .mapboxgl-popup-tip{
    border-top-color: $construction_road !important;
  }
  .mapboxgl-popup-content{
    background-color: $construction_road !important;
  }
}


.ws-popup-border{
  border-radius: 35px;
  overflow: hidden;
  position: relative;
}

.ws-popup-border-color-black{
  border:5px solid $black;
}

.ws-popup-border-color-white{
  border:5px solid $white;
}

.ws-popup-border-color-gray{
  border:5px solid $gray;
}

.ws-popup-border-color-clutter{
  border:5px solid $clutter;
}

.ws-popup-border-color-garbage{
  border:5px solid $garbage;
}

.ws-popup-border-color-graffiti{
  border:5px solid $graffiti;
}

.ws-popup-border-color-bad-billboard{
  border:5px solid $bad_billboard;
}

.ws-popup-border-color-potholes{
  border:5px solid $potholes;
}

.ws-popup-border-color-construction-road{
  border:5px solid $construction_road;
}