@import "variables.scss";

.box-container {
  position: relative;
  display: inline-block;
}

.box-toolbox {
  overflow-y: auto;
  padding: 5px 20px 20px 20px;
  background-color:  $gray; 
  border: 7px solid $black; 
  border-radius: 20px;
  margin: 5px 0rem 0rem 10px;
  min-height: 200px;
  min-width: 250px;
  position: relative;
}

.box-toolbox label {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
}

.box-toolbox-input {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  background-color: $gray;
  cursor: pointer;
  margin-right: 15px;
}

.box-toolbox-input-white{
  border: 4px solid $white;
}.box-toolbox-input-white:checked {
  background-color: $white;
}

.box-toolbox-input-black{
  border: 4px solid $black;
}.box-toolbox-input-black:checked {
  background-color: $black;
}

.box-toolbox-input-gray{
  border: 4px solid $gray;
}.box-toolbox-input-gray:checked {
  background-color: $gray;
}


.box-toolbox-input-clutter{
  border: 4px solid $clutter;
}.box-toolbox-input-clutter:checked {
  background-color: $clutter;
}

.box-toolbox-input-garbage{
  border: 4px solid $garbage;
}.box-toolbox-input-garbage:checked {
  background-color: $garbage;
}

.box-toolbox-input-construction-road{
  border: 4px solid $construction_road;
}.box-toolbox-input-construction-road:checked {
  background-color: $construction_road;
}

.box-toolbox-input-graffiti{
  border: 4px solid $graffiti;
}.box-toolbox-input-graffiti:checked {
  background-color: $graffiti;
}

.box-toolbox-input-bad-billboard{
  border: 4px solid $bad_billboard;
}.box-toolbox-input-bad-billboard:checked {
  background-color: $bad_billboard;
}

.box-toolbox-input-potholes{
  border: 4px solid $potholes;
}.box-toolbox-input-potholes:checked {
  background-color: $potholes;
}
