@import "_loader.scss";
@import "_box-container.scss";
@import "_filter-panel.scss";
@import "_variables.scss";
@import "_WSMap.scss";


$font-family-source-code-pro: 'Source Code Pro', monospace;

.header-text {
  font-family: $font-family-source-code-pro;
  font-weight: bold;
  font-size: 32px;
}

.body-text {
  font-family: $font-family-source-code-pro;
  font-weight: normal;
  font-size: 18px;
}

.accent-text {
  font-family: $font-family-source-code-pro;
  font-weight: lighter;
  font-size: 14px;
}

.button-text {
  font-family: $font-family-source-code-pro;
  font-weight: bold;
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.text-primary{
  color: $black;
}

.list-primary{
  padding-bottom: 15px;
  padding-right: 15px;
} 

.btn-center{
  position: relative;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
}

.btn-primary{
  background-color: $black;
  color: $white; 
  border: 3px solid $white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px 40px 10px 40px;
  
  &:hover{
    cursor: pointer;
  }
}

.welcome-text-container{
  max-width: 700px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pointer-cursor{
  cursor: pointer;
}


.logo-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.header {
  background-color: $white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.full-screen{
  height: 100%;
  width: 100%;
}

.flex-row {
  display: flex;
  justify-content: flex-start;
}

.flex-row-space-between{
  display: flex;
  justify-content: center;
}

.header-no-margin{
  margin: 0px;
}

.sub-header-margin{
  margin-top: 0.25rem;
}

.medium-size{
  scale: 70%;
}

.color-black{
  background-color: $black;
}

.color-white{
  background-color: $black;
}

.color-gray{
  background-color: $gray;
}

.color-clutter{
  background-color: $clutter;
}

.color-garbage{
  background-color: $garbage;
}

.color-graffiti{
  background-color: $graffiti;
}

.color-bad-billboard{
  background-color: $bad_billboard;
}

.color-potholes{
  background-color: $potholes;
}

.color-construction-road{
  background-color: $construction_road;
}

.custom-marker{
  opacity: 0.8;
  cursor: pointer;
  border: 2px solid $black;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.error-image {
    width: 339px; 
    height: 366px;
}
